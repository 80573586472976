import React from "react";

const ArticleSection = ({ article }) => {
  if (!article) {
    return <p>Article not found.</p>;
  }

  return (
    <div style={styles.container}>
      {/* Title */}
      <h1 style={styles.title}>{article?.title}</h1>

      {/* Custom underline */}
      <div style={styles.underline}></div>

      {/* Category & Read Time */}
      <p style={styles.meta}>
        <strong></strong> {article?.by} | <strong>{article?.date}</strong>
      </p>

      {/* Rendering dynamic content */}
      {article?.description?.map((desc, index) => (
        <div key={index} style={styles.contentBlock}>
          {/* Render Title if Available */}
          {desc?.dsc_title && <h2 style={styles.subTitle}>{desc?.dsc_title}</h2>}

          {/* Render Plain Text */}
          {desc?.dsc_type === "text" && <p style={styles.content}>{desc?.dsc_text}</p>}

          {/* Render Unordered List */}
          {desc?.dsc_type === "ul" && (
            <div>
              <h3 style={styles.listHeader}>{desc?.dsc_ul_header}</h3>
              {desc?.dsc_sort_dsc && <p>{desc?.dsc_sort_dsc}</p>}
              <div style={styles.listContainer}>
                {desc?.dsc_ul_image && (
                  <img src={desc?.dsc_ul_image} alt="" style={styles.listImage} />
                )}
                <ul style={styles.list}>
                  {desc?.dsc_ul_list?.map((item, idx) => (
                    <li key={idx} style={styles.listItem}>
                      <strong style={styles.listItemTitle}>{item?.title}</strong>
                      <br /> {item?.text}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "100%",
    margin: "auto",
    padding: "20px 80px",
    fontFamily: "Arial, sans-serif",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#0F2A4D",
  },
  underline: {
    width: "100px",
    height: "4px",
    backgroundColor: "#E3A000",
    margin: "10px 0",
  },
  meta: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#222",
    marginTop: "10px",
  },
  contentBlock: {
    marginTop: "20px",
  },
  subTitle: {
    fontSize: "18px",
    color: "#0F2A4D",
    fontWeight: "bold",
  },
  content: {
    fontSize: "16px",
    color: "#333",
    lineHeight: "1.6",
  },
  listHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#0F2A4D",
  },
  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    gap: "20px",
  },
  listImage: {
    width: "100%",
    maxWidth: "400px", // Limits max width
    height: "350px",
    borderRadius: "5px",
  },
  list: {
    paddingLeft: "0",
    listStyleType: "none", // Removes bullet points
    flex: "1", // Allows text to take available space
  },
  listItem: {
    marginBottom: "10px", // Adds space between list items
    fontSize: "16px",
    lineHeight: "1.6",
  },
  listItemTitle: {
    fontWeight: "600", // Slightly thinner bold text
    color: "rgba(15, 42, 77, 0.8)", // Adds color to the strong tag
  },
};

// Responsive Styles for Small Screens
const responsiveStyles = `
  @media (max-width: 768px) {
    .listContainer {
      flex-direction: column;
      align-items: center;
    }
    .listImage {
      max-width: 100%;
      height: auto;
    }
    .list {
      text-align: left;
    }
  }
`;

// Inject responsive styles dynamically
const styleTag = document.createElement("style");
styleTag.innerHTML = responsiveStyles;
document.head.appendChild(styleTag);

export default ArticleSection;

import React from "react";
import "../../styles/LatestNewsCard.css";

const LatestArticleCard = ({ image, time, title, category, readTime }) => {
  return (
    <div className="latest-article-card">
      <div className="article-image">
        <img src={image} alt={title} />
      </div>
      <div className="article-details">
        <div className="author-info">
          {/* <img src={authorImg} alt={author} className="author-img" />
          <span className="author-name">{author}</span>
          <span className="dot">•</span> */}
          <span className="time">{time}</span>
        </div>
        <h3 className="article-title">{title}</h3>
        <div className="article-meta">
          <span className="category">{category}</span>
          <span className="dot">•</span>
          <span className="read-time">{readTime} read</span>
        </div>
      </div>
    </div>
  );
};

export default LatestArticleCard;

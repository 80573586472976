import React from "react";
import { useNavigate } from "react-router-dom";

import '../../styles/NewsHeroSection.css';
import NewsListItem from "../sharedComponents/NewsListItem";
// import RecentNewsCard from "../sharedComponents/recentNewsCard";

// const recentNewsData = [
//     {
//         title: "Boom, Snoop Dogg: Breaking Electrifies Paris 2024 Olympics",
//         description: "The inaugural Olympic breaking competition kicked off at La Concorde on Friday ...",
//         image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTNgudpm1dNdUA7cfyw9IgaxkL7Mc-oW9T6EWuESATInWP8a4Xn",
//         category: "Olympics",
//         time: "4 min read"
//     },
//     {
//         title: "Carlos Nasar wins 89kg gold and breaks World Record",
//         description: "Bulgaria’s Karlos Nasar secured the men's weightlifting 89 kg gold medal at...",
//         image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTNgudpm1dNdUA7cfyw9IgaxkL7Mc-oW9T6EWuESATInWP8a4Xn",
//         category: "Olympics",
//         time: "6 min read"
//     },
//     {
//         title: "The People's Republic of China reigns supreme in men's team",
//         description: "The People's Republic of China defended their gold medal in the men's table tennis team...",
//         image: "https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcTNgudpm1dNdUA7cfyw9IgaxkL7Mc-oW9T6EWuESATInWP8a4Xn",
//         category: "Olympics",
//         time: "2 min read"
//     }

// ]

const HeroSection = ({data}) => {
  const navigate = useNavigate();
  return (
    <div className="hero-container">

      <div className="hero-left" onClick={()=>navigate(`/news-details/${data[0]?._id}`)}>
        <h1 className="hero-title">
          {data[0]?.title}
        </h1>
        <div className="hero-meta">
          <span className="category">{data[0]?.by}</span>
          <span className="read-time"> | {data[0]?.date}</span>
        </div>
        <img src={data[0]?.image} alt="Basketball" className="hero-img" />
      </div>

      {/* <div className="hero-right">
        {data.slice(1,data.length).map((news, index) => (
          <div key={index}>
            <NewsListItem item={news} />
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default HeroSection;

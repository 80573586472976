import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";

import { FaTimes, FaSearch } from "react-icons/fa";
import ArticleSection from "../components/News/NewsDescription";

import newsData from "../NewsData/newsData";

const NewsDetails = () => {
  const [article, setArticle] = useState({});
  const { id } = useParams();

  const findArticle = async() => {
    const article = newsData.find((item) => item._id === id);
    setArticle(article);
  }

  useEffect(() => {
    let mounted = true;
    if(mounted){
      window.scrollTo(0, 0);
      findArticle();
    }
    return () => {
      mounted = false;
    }
  }, [id,findArticle]);
  console.log('article');
  return (
    <div>
        <div style={styles.container}>
          {/* Close and Zoom Icons */}
          {/* <div style={styles.iconContainer}>
              <FaSearch style={styles.icon} />
              <FaTimes style={styles.icon} />
          </div> */}

          {/* Header Section */}
          {/* <div style={styles.header}>
              <span style={styles.badge}>{article?.by}</span>
              <span style={styles.date}>{article?.date}</span>
          </div> */}

          {/* Title */}
          {/* <h1 style={styles.title}>{article?.title}</h1> */}

          {/* Subtitle */}
          {/* <p style={styles.subtitle}>
              Optimal strategies for achieving profitable harvests involve a
              comprehensive approach to farm management, selection of appropriate
              crop varieties, implementation of efficient practices.
          </p> */}

          {/* Featured Image */}
          <div style={styles.imageContainer}>
              <img
              src={article?.image}
              alt="Article"
              style={styles.image}
              />
          </div>
        </div>
        <ArticleSection article={article} />
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "100%",
    margin: "auto",
    padding: "0 80px",
    textAlign: "center",
    backgroundColor: "#fff",
  },
  iconContainer: {
    // marginTop:'50px',
    position: "absolute",
    top: "20px",
    right: "20px",
    display: "flex",
    gap: "15px",
  },
  icon: {
    fontSize: "18px",
    color: "#333",
    cursor: "pointer",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    marginBottom: "10px",
  },
  badge: {
    backgroundColor: "#d9f99d",
    color: "#3a5a40",
    padding: "5px 12px",
    // borderRadius: "15px",
    fontSize: "12px",
    fontWeight: "bold",
  },
  date: {
    fontSize: "14px",
    color: "#666",
  },
  title: {
    fontSize: "36px",
    fontWeight: "bold",
    margin: "10px 0",
    color: "#222",
  },
  subtitle: {
    fontSize: "16px",
    color: "#666",
    maxWidth: "600px",
    margin: "auto",
  },
  imageContainer: {
    marginTop: "20px",
    textAlign: "center",
  },
  image: {
    width: "1200px",
    height:'100%',
    // padding:'20px',
    // borderRadius: "10px",
  },
};

export default NewsDetails;

const AccroListItem = ({ data }) => {
  return (
    <div style={styles.valueItem}>
        <h3 style={styles.valueTitle}>{data.title}</h3>
        <p style={styles.text}>{data.description}</p>
    </div>
  );
}

const styles = {
    text: {
        fontSize: "16px",
        color: "#333",
        lineHeight: "1.6",
        marginBottom: "15px",
    },
    valueItem: {
        marginTop: "20px",
        padding: "10px",
        borderLeft: "5px solid #E3A000",
        backgroundColor: "#f9f9f9",
    },
    valueTitle: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#0F2A4D",
    },
}

export default AccroListItem;
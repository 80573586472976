import { useNavigate } from 'react-router-dom';

import '../../styles/NewsListItem.css';

const NewsListItem = ({ item }) => {
    const navigate = useNavigate();
    console.log('item : ',item)
    return (
        <div className="NewsPress-news-item">
            <div style={{display:'flex',gap:'20px',flexWrap:'wrap'}}>
                <img src={item?.image} alt="news" style={{width:'150px',height:'100px',borderRadius:'2px'}} />
                <div>
                    <p className="NewsPress-news-date">{item?.date}</p>
                    <h3 className="NewsPress-news-headline">{item?.title}</h3>
                </div>
            </div>
            <p className="NewsPress-news-button" onClick={()=>navigate(`/news-details/${item?._id}`)}>Read More</p>
        </div>
    )
}

export default NewsListItem;

import React from "react";
import "../../styles/HomeApproach.css";

const HomeApproach = () => {
  return (
    <section className="approach">
      <h2 className="approach-title">OUR APPROACH</h2>
      <p className="approach-description">
        Our methodology is built on three key pillars: <strong>IDEA, INITIATIVE, and IMPLEMENTATION.</strong>  
        We collaborate closely with our clients to develop bespoke strategies that integrate human rights,  
        environmental and social safeguards, and international best practices.  
        This holistic approach ensures that every project we undertake delivers measurable results and sustainable impact.
      </p>
    </section>
  );
};

export default HomeApproach;

import gwc_art_01_10 from '../assets/news_assets/gwc_art_01_10.JPG';

import gwc_art_02_01 from '../assets/news_assets/gwc_art_02_01.jpg';
import gwc_art_02_02 from '../assets/news_assets/gwc_art_02_02.jpg';
// import gwc_art_02_03 from '../assets/news_assets/gwc_art_02_03.jpg';

import gwc_art_03_01 from '../assets/news_assets/gwc_art_03_01.jpg';

const newsData = [
    {
        _id:'k45kj21k5j145ku152il',
        title: "GreenWorks Consulting’s CEO, Mr. Kamlesh Kumar Pathak, Plays a Key Role in the Best Practice Forum of the Friendship Project",
        image: gwc_art_01_10,
        date: "February 18, 2025",
        by: "By GreenWorks Consultancy",
        description: [
            {
                dsc_type: "text",
                dsc_text:"The Best Practice Forum of the Friendship Project, co-funded by the European Union, successfully took place on February 18, 2025. The event focused on fostering city-to-city cooperation between the European Union (EU) and Mongolia by promoting decentralization, sustainable urban development, and innovative partnerships."
            },
            {
                dsc_type: "text",
                dsc_text:"One of the key contributors to this forum was Mr. Kamlesh Kumar Pathak, CEO of GreenWorks Consulting, who played a vital role as the Chief Technical Advisor. Additionally, on the second day of the forum, he was a prominent speaker, sharing his expertise on sustainable urban development, decentralized cooperation, and innovative city partnerships. His insights and technical guidance provided valuable perspectives on how cities can collaborate to achieve long-term sustainability."
            },
            {
                dsc_type: "ul",
                dsc_ul_header:'Key Discussions and Highlights of the Forum',
                dsc_sort_dsc:'The Best Practice Forum focused on several crucial topics aimed at enhancing international cooperation in sustainable urban planning',
                // dsc_sub_title:''
                dsc_ul_list:[
                    {
                        title:'City-to-City Cooperation : ',
                        text:'Strengthening partnerships between EU and Mongolian cities to promote decentralized urban governance and development.'
                    },
                    {
                        title:"Local Authorities' Role in Sustainability",
                        text:"Showcasing real-world experiences of local governments in implementing sustainable projects under EU-funded initiatives."
                    },
                    {
                        title:'Sustainable Urban Transition : ',
                        text:'Exploring how cities can transition towards greener, smarter, and more resilient urban environments.'
                    },
                    {
                        title:'Knowledge Exchange : ',
                        text:'Learning from successful EU projects, programs, and networks to improve urban planning in Mongolia.'
                    }
                ],
            },
            {
                dsc_type: "text",
                dsc_text:"The forum featured distinguished speakers, including Nicolas Gérard, Policy Officer from the European Commission’s Directorate-General for International Partnerships (DG INTPA), G2 Division, and Bella Tskhelishvili, Senior Advisor at CEMR–PLATFORMA (Council of European Municipalities and Regions). They provided in-depth insights into EU project approaches, priority areas, and the Sustainable Cities Partnership Program."
            },
            {
                dsc_type: "text",
                dsc_text:"In addition, mayors from Rijeka and Ludbreg (Croatia) and Bar (Montenegro) shared their experiences implementing EU-funded urban projects, emphasizing the importance of collaboration, knowledge-sharing, and technical expertise. The governors of Tuv and Selenge provinces also presented their regional development strategies, highlighting their vision for sustainable urbanization in Mongolia."
            },
            {
                dsc_type:'ul',
                dsc_ul_header:'Innovation and Pilot Projects Under the Friendship Project',
                dsc_sort_dsc:'The forum also showcased innovative initiatives that received grants under the Friendship Project, including:',
                // additional_title:'Startup Grants:',
                dsc_ul_list:[
                    {
                        title:'Ikher Dol Stove – ',
                        text:'A sustainable energy solution designed to improve efficiency in urban households.'
                    },
                    {
                        title:'Science Café – ',
                        text:'A knowledge-sharing hub promoting scientific education and entrepreneurship.'
                    },
                    {
                        title:'Home Center – ',
                        text:'A community-driven initiative providing housing solutions for urban populations.'
                    },
                    {
                        title:'Engineering Network Connection Design for Ger Districts – ',
                        text:'A project aimed at improving infrastructure and basic services in underdeveloped areas.'
                    }
                ]
            },
            {
                dsc_type: "text",
                dsc_text:'Each of these organizations presented their objectives, solutions, and future plans, engaging in discussions on potential collaboration opportunities with EU and Mongolian partners.'
            },
            {
                dsc_type: "text",
                dsc_title:'GreenWorks Consulting’s Contribution to Sustainable Urban Development',
                dsc_text:'As a leading expert in sustainable urban planning and infrastructure development, GreenWorks Consulting continues to play a pivotal role in advising and guiding city authorities on sustainable urban solutions. Mr. Kamlesh Kumar Pathak emphasized the need for stronger international cooperation, adoption of best practices, and innovative strategies to build cities that are more resilient, smart, and environmentally friendly.'
            },
            {
                dsc_type: "text",
                dsc_text:'The event successfully strengthened international ties and paved the way for future collaborations that will contribute to sustainable city development in Mongolia and beyond.'
            }

        ],
    },
    {
        _id:'k45kj21k5j145ku152im',
        title: "A New Dawn for Egypt’s Mangroves: GreenWorks Consulting Leads the Green Revolution.",
        image: gwc_art_02_01,
        date: "February 18, 2025",
        by: "By GreenWorks Consultancy",
        description: [
            {
                dsc_type: "text",
                dsc_text:"Egypt’s stunning Red Sea coast is witnessing a transformative environmental journey as GreenWorks Consulting, in partnership with the Environment Without Borders Foundation, spearheads an ambitious initiative to restore and conserve its precious mangrove ecosystems. This groundbreaking project is not just about reviving nature’s beauty—it’s a bold stride in the fight against climate change."
            },
            {
                dsc_type: "text",
                dsc_text:"For years, the coastal blue carbon ecosystem has been under threat from environmental degradation and human pressures. Now, with a visionary approach, the project targets the restoration of over 6,406 hectares along the Red Sea coast and Abu Ali Island. The aim is to rejuvenate existing mangrove stands and introduce new plantations that will collectively sequester an estimated 5,267,219 tCO₂e over 20 years—an annual average of around 263,361 tCO₂e."
            },
            {
                dsc_type: "ul",
                dsc_ul_header:'GreenWorks Consulting’s Vital Role',
                dsc_sort_dsc:'GreenWorks Consulting is at the heart of this ecological revival, orchestrating a series of strategic actions that blend innovation with community engagement:',
                dsc_ul_image: gwc_art_02_02,
                dsc_ul_list:[
                    {
                        title:'Securing Approvals and Preparing the Land:',
                        text:'By swiftly obtaining critical regulatory approvals from the National Social Solidarity Ministry, the team has ensured a smooth launch of project activities. Their meticulous efforts in clearing the land and setting up optimal conditions for mangrove growth form the bedrock of this initiative.'
                    },
                    {
                        title:'Revitalizing Greenhouses and Nurturing Seedlings:',
                        text:'Recognizing the importance of a nurturing environment, GreenWorks Consulting has rehabilitated existing greenhouses to foster seed germination. Local seedlings have been carefully sourced and prepared for transplantation, using a dual approach that combines greenhouse nurturing with direct seeding.'
                    },
                    {
                        title:'Empowering Local Communities:',
                        text:'Sustainability is more than just environmental—it’s social, too. The project actively involves local communities through training sessions and hands-on workshops. This not only equips residents with vital conservation skills but also instills a strong sense of ownership and pride in preserving their natural heritage.'
                    }
                ]
            },
            {
                dsc_type: "text",
                dsc_title:'A Catalyst for Change',
                dsc_text:'As you browse through the captivating photos capturing land preparation, greenhouse operations, and community engagement sessions, the energy and dedication of the team become unmistakably clear. GreenWorks Consulting’s leadership is setting the stage for a resilient, sustainable future where innovative environmental practices meet local empowerment.'   
            },
            {
                dsc_type: "text",
                dsc_text:'This initiative is a shining example of how smart planning and collaborative effort can transform vulnerable landscapes into thriving, green havens. It is a call to action—an invitation for all of us to support and be part of a movement that not only restores nature but also builds a legacy of environmental stewardship'
            }   
        ]
    },
    {
        _id:'k45kj21k5j145ku152in',
        title: "GreenWorks Consulting Champions Sustainable Urban Development at the Friendship Project Forum",
        image: gwc_art_03_01,
        date: "February 18, 2025",
        by: "By GreenWorks Consultancy",
        description: [
            {
                dsc_type: "text",
                dsc_text:"On February 18, 2025, the much-anticipated Best Practice Forum of the Friendship Project—co-funded by the European Union—brought together international experts, city leaders, and innovative minds under one roof. This landmark event focused on building city-to-city cooperation between the EU and Mongolia, fostering decentralized governance, and paving the way for sustainable urban development."
            },
            {
                dsc_type: "text",
                dsc_text:"At the forefront of the forum’s success was Mr. Kamlesh Kumar Pathak, CEO of GreenWorks Consulting. Serving as the Chief Technical Advisor and a key speaker on the second day, Mr. Pathak captivated the audience with his in-depth understanding of sustainable urban practices. His presentations not only highlighted innovative solutions for decentralized cooperation but also underscored the importance of international partnerships in addressing the challenges of urban growth and climate change."
            },
            {
                dsc_type: "text",
                dsc_title:'A Forum for Innovation and Collaboration',
                dsc_text:'The event was structured around several critical themes designed to spark new ideas and practical solutions for modern urban challenges. Distinguished speakers, including Nicolas Gérard from the European Commission and Bella Tskhelishvili of CEMR–PLATFORMA, provided rich insights into EU project strategies, the Sustainable Cities Partnership Program, and priority areas for urban development. Their discussions set the stage for a dialogue that transcended borders and traditional approaches to urban planning.'
            },
            {
                dsc_type: "text",
                dsc_text:'City leaders from Rijeka, Ludbreg, and Bar shared firsthand experiences of implementing EU-funded projects, illustrating how collaborative efforts can transform urban landscapes. Similarly, the governors of Mongolia’s Tuv and Selenge provinces presented forward-thinking regional development plans, emphasizing a shared vision for resilient and eco-friendly cities.'
            },
            {
                dsc_type: "text",
                dsc_title:'Innovation on Display',
                dsc_text:'The forum was not just a platform for discussion—it was a showcase for groundbreaking initiatives that have the potential to redefine urban sustainability. Innovative projects under the Friendship Project were unveiled, ranging from startup grants for solutions like the Ikher Dol Stove, designed to enhance energy efficiency in urban households, to the Science Café, a dynamic hub fostering scientific education and entrepreneurial spirit. Pilot projects, including the community-driven Home Center and the Engineering Network Connection Design for Ger Districts, demonstrated practical steps toward improving infrastructure and basic services in underdeveloped areas.'
            },
            {
                dsc_type: "text",
                dsc_text:'Each project presented at the forum reflected a deep commitment to creativity and practical impact, with collaborative discussions paving the way for future partnerships between EU and Mongolian stakeholders.'
            },
            {
                dsc_type: "text",
                dsc_title:'GreenWorks Consulting’s Visionary Role',
                dsc_text:'Throughout the forum, GreenWorks Consulting’s role was both strategic and inspirational. Mr. Kamlesh Kumar Pathak’s expertise in sustainable urban planning shone through as he articulated the need for robust international cooperation, the adoption of best practices, and innovative strategies to build cities that are not only smart but also resilient and environmentally friendly. His insights helped frame the conversation around long-term sustainability and set a clear agenda for future collaborations.'
            },
            {
                dsc_type: "text",
                dsc_text:'By bridging technical guidance with practical, real-world applications, GreenWorks Consulting has reinforced its reputation as a leader in sustainable urban development. The forum’s success is a testament to their unwavering commitment to transforming urban challenges into opportunities for growth and innovation.'
            },
            {
                dsc_type: "text",
                dsc_title:'Looking Ahead',
                dsc_text:'As the forum concluded, it left behind a legacy of strengthened international ties and a roadmap for sustainable urban transformation. With leaders like Mr. Kamlesh Kumar Pathak at the helm, GreenWorks Consulting continues to inspire change, championing a future where cities thrive through collaboration, innovative thinking, and a shared commitment to environmental stewardship.'
            },
            {
                dsc_type: "text",
                dsc_text:'For more inspiring stories and updates on sustainable urban development initiatives, stay tuned to our website.'
            }
        ]
    }
];

export default newsData;
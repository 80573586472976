import React from "react";
import "../../styles/HomeAbout.css";

const HomeAbout = () => {
  return (
    <div className="home-about">
      <h1>WELCOME TO GREENWORKS CONSULTING</h1>
      <p>
        <strong>YOUR TRUSTED PARTNER IN GRANT PROJECTS & SUSTAINABLE DEVELOPMENT</strong>
      </p>
      <p>
        At GreenWorks Consulting, we specialize in providing comprehensive consulting services for projects—especially those centered on grants. 
        Our expertise spans the entire project lifecycle: from crafting winning proposals and securing funding to managing full-scale 
        implementation and ensuring successful project completion. We are dedicated to transforming innovative ideas into impactful, 
        sustainable solutions.
      </p>
    </div>
  );
};

export default HomeAbout;

const Footer = () => {
    return (
      <footer style={styles.footer}>
        <div style={styles.container}>
          {/* About Section */}
          <div style={styles.column}>
            <h3 style={styles.heading}>About</h3>
            <ul style={styles.list}>
              <li><a style={styles.link} href="#">Contact</a></li>
              <li><a style={styles.link} href="#">Management board</a></li>
              <li><a style={styles.link} href="#">Whistleblowing</a></li>
              <li><a style={styles.link} href="#">GWC companies & offices</a></li>
            </ul>
          </div>
  
          {/* Themes & Projects Section */}
          <div style={styles.column}>
            <h3 style={styles.heading}>Themes & Projects</h3>
            <ul style={styles.list}>
              <li><a style={styles.link} href="#">GWC's business areas</a></li>
              <li><a style={styles.link} href="#">GWC projects</a></li>
            </ul>
          </div>
  
          {/* Jobs Section */}
          <div style={styles.column}>
            <h3 style={styles.heading}>Jobs</h3>
            <ul style={styles.list}>
              <li><a style={styles.link} href="#">Job opportunities</a></li>
              <li><a style={styles.link} href="#">Working for GWC as an expert</a></li>
              <li><a style={styles.link} href="#">Working with GWC headquarters</a></li>
              <li><a style={styles.link} href="#">Working with GWC in a framework</a></li>
            </ul>
          </div>
  
          {/* News Section */}
          <div style={styles.column}>
            <h3 style={styles.heading}>News</h3>
            <ul style={styles.list}>
              <li><a style={styles.link} href="/news">News</a></li>
              <li><a style={styles.link} href="#">Newsletters</a></li>
              <li><a style={styles.link} href="#">Subscribe to newsletter</a></li>
            </ul>
          </div>
        </div>
      </footer>
    );
  };
  
  const styles = {
    footer: {
      backgroundColor: "#002855",
      padding: "40px 0",
    },
    container: {
      maxWidth: "1100px",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      padding: "0 20px",
    },
    column: {
      flex: "1",
      minWidth: "200px",
    },
    heading: {
      fontSize: "18px",
      color: "#fff",
      marginBottom: "10px",
    },
    list: {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    listItem: {
        color: "#fff",
        marginBottom: "8px",
    },
    link: {
      textDecoration: "none",
      color: "#fff",
      fontSize: "14px",
    },
  };
  
  export default Footer;
  
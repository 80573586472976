import React from "react";
import { Link } from "react-router-dom";
import '../../styles/NewsPress.css';
import NewsListItem from "../sharedComponents/NewsListItem";

const NewsPress = ({data}) => {
  return (
    <div >
       <h2 className="NewsPress-news-title">News & Press</h2>
       <p className="NewsPress-news-subtitle" style={{margin:0}}>LATEST FROM Greenworks</p>
      <div className="NewsPress-news-list">
        {data.map((item, index) => (
            <div key={index}>
                <NewsListItem item={item} />
            </div>
        ))}
      </div>
      <Link to="/news" className="NewsPress-more-news">More News</Link>
    </div>
  );
};

export default NewsPress;

import React, { useState, useEffect } from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../../styles/HomeHeroSection.css";

import Breaking_the_Barriers_Interegional_Collaboration_for_Making_Carbon_Market_Works_ROK_and_Central_Asia_1 from '../../assets/Breaking_the_Barriers-Interegional_Collaboration_for_Making_Carbon_Market_Works-ROK_and_Central_Asia_1.jpg';
import Connecting_the_Bridge_EU_Darkhan_Mongolia_Climate_Action_Pathways_1 from '../../assets/Connecting_the_Bridge_EU_Darkhan_Mongolia_Climate_Action_Pathways_1.jpg';
import Connecting_the_Bridge_EU_Darkhan_Mongolia_Climate_Action_Pathways_2 from '../../assets/Connecting_the_Bridge_EU_Darkhan_Mongolia_Climate_Action_Pathways_2.jpg';
import Forging_Alliance_and_Cooperation_EU_Darkhan_Mongolia_Innovation_Ecosystem_4 from '../../assets/Forging_Alliance_and_Cooperation_EU_Darkhan_Mongolia_Innovation_Ecosystem_4.jpg';
import Setting_up_Innovation_Chain_EU_Darkhan_Mongolia_Innovation_Ecosystems_3 from '../../assets/Setting_up_Innovation_Chain_EU_Darkhan_Mongolia_Innovation_Ecosystems_3.jpg';

const slides = [
    {
        image: Connecting_the_Bridge_EU_Darkhan_Mongolia_Climate_Action_Pathways_1,
        text: "Connecting the Bridge EU Darkhan Mongolia Climate Action Pathways",
    },
    {
        image: Breaking_the_Barriers_Interegional_Collaboration_for_Making_Carbon_Market_Works_ROK_and_Central_Asia_1,
        text: "Breaking the Barriers Interegional Collaboration for Making Carbon Market Works ROK and Central Asia",
    },
    {
        image: Connecting_the_Bridge_EU_Darkhan_Mongolia_Climate_Action_Pathways_2,
        text: "Connecting the Bridge EU Darkhan Mongolia Climate Action Pathways",
    },
    {
        image: Forging_Alliance_and_Cooperation_EU_Darkhan_Mongolia_Innovation_Ecosystem_4,
        text: "Forging Alliance and Cooperation EU Darkhan Mongolia Innovation Ecosystem",
    },
    {
        image: Setting_up_Innovation_Chain_EU_Darkhan_Mongolia_Innovation_Ecosystems_3,
        text: "Setting up Innovation Chain EU Darkhan Mongolia Innovation Ecosystems",
    },
];

const HomeHeroSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, []);

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   arrows: true,
  // };

  return (
    <section className="hero">
        <div className="slider"> 
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? "active" : ""}`}
              style={{ backgroundImage: `url(${slide.image})`}}
            >
              <div className="hero-content" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <h1>{slide.text}</h1>
                {/* <button className="hero-btn">READ MORE</button> */}
              </div>
            </div>
          ))}
        </div>
    </section>
  );
};

export default HomeHeroSection;

import React from "react";
import "../../styles/HomeServices.css";
import AccroListItem from "../sharedComponents/AccroListItem";

const servicesData = [
  {
    title: "PROJECT DEVELOPMENT FOR FUNDING",
    points: [
      "Transform your innovative ideas into compelling proposals.",
      "Leverage our strategic planning and expert guidance to secure grants and competitive bids.",
    ],
  },
  {
    title: "PROJECT MANAGEMENT FOR SUSTAINABILITY",
    points: [
      "Enjoy end-to-end project execution with a focus on quality and efficiency.",
      "Benefit from our rigorous planning, financial management, and reporting systems to ensure success.",
    ],
  },
  {
    title: "EVALUATION & IMPACT ASSESSMENT",
    points: [
      "Receive detailed evaluations that measure outcomes and drive continuous improvement.",
      "Implement results-based management to achieve tangible impact.",
    ],
  },
  {
    title: "CAPACITY BUILDING",
    points: [
      "Empower your organization through tailored training and technical support.",
      "Build long-term resilience and sustainable growth with our expertise.",
    ],
  },
];

const HomeServices = () => {
  return (
    <section className="services">
      <h2 className="services-title">OUR SERVICES</h2>
      <div className="services-container">
        {servicesData.map((service, index) => (
          <div key={index} style={{display:'flex',flex:1,boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'}}>
            <AccroListItem
              data={{
                title: service.title,
                description: (
                  <ul>
                    {service.points.map((point, idx) => (
                      <li key={idx}>{point}</li>
                    ))}
                  </ul>
                ),
              }}
            />
          </div>
        ))}
      </div>
    </section>
  );
};

export default HomeServices;

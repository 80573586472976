import HomeAbout from "../components/HomeComponents/HomeAbout";
import HomeApproach from "../components/HomeComponents/HomeApproach";
import HomeGetInTouch from "../components/HomeComponents/HomeGetInTouch";
import HomeHeroSection from "../components/HomeComponents/HomeHeroSection";
import HomeServices from "../components/HomeComponents/HomeServices";
import HomeWhyChooseUs from "../components/HomeComponents/HomeWhyChooseUs";
// import NewsHighlight from "../components/News/newsHighlisght";
import NewsPress from "../components/News/NewsPress";

// import NewsHeroSection from "../components/News/NewsHeroSection";
import newsData from "../NewsData/newsData";
const Home = () => {
  return (
    <div style={{width:'100%'}}>
      <HomeHeroSection />
      <div style={{padding:'30px'}}>
        <NewsPress data={newsData} />
      </div>
      {/* <NewsHeroSection /> */}
      <HomeAbout />
      <HomeServices />
      <HomeApproach />
      <HomeWhyChooseUs />
      <HomeGetInTouch />
    </div>
  );
};

export default Home;
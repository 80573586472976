import { Outlet } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const PageLayOut = () => {
    return (
        <div>
            <div className="navbar-container" style={{backgroundColor:'rgba(125,125,125,0.1)'}}>
                <NavBar />
            </div>
            <div className="main">
                <Outlet />
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};

export default PageLayOut;
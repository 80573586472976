import React from "react";
import "../../styles/AboutUsHeroSection.css"; // Import the CSS file

import sideImage from '../../assets/Connecting_the_Bridge_EU_Darkhan_Mongolia_Climate_Action_Pathways_1.jpg'

const AboutUsHeroSection = () => {
  return (
    <section className="about-section">
      <div className="about-header">
        <p className="welcome-text">Welcome, We're</p>
        <h1 className="company-name">Greenwork Consultancy</h1>
      </div>
      <div className="about-content">
        <div className="about-image">
          <img
            src={sideImage} // Replace with actual image
            alt="Business Meeting"
          />
        </div>
        <div className="about-text">
          <p className="small-title">SOMETHING KNOW ABOUT US</p>
          <h2 className="section-title">About Us</h2>
          <p className="about-description">
            GreenWorks Consulting is a development consulting firm which provides consulting, training, research, engineering and technology solutions 
            to stakeholders from all parts of the world. We partner and collaborate with national, international, bilateral, multilateral agencies. 
            Moreover, we further work with public, private and social enterprise sectors in all part of the world to mainstream sustainable development 
            at all le/vel. We strive to integrate economic, social, environmental and sustainable development challenges and recognizing their 
            interlinkages in exploring solutions, so as to achieve goals and targets of sustainable development in all its dimensions with an objective 
            to establish equity and social justice.
          </p>
          <p className="about-description">
            We work diligently to foster international development and to transform the 
            society by delivering knowledge and solutions which assist us in transition 
            towards a more resilient and prosperous community and economy.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHeroSection;

import React from "react";
import AboutUsHeroSection from "../components/AboutUsComponents/AboutUsHeroSection";
import MissionVision from "../components/AboutUsComponents/MissionVision";
import StoryComponent from "../components/AboutUsComponents/StoryComponent";

const AboutUs = () => {
  return (
    <div>
     <AboutUsHeroSection /> 
     <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <StoryComponent />
     </div>
      <div style={styles.container}>
        {/* <div style={{width:'100%'}}>
          <h2 style={styles.sectionTitle}>Our Core Values</h2>
          <div style={{display:'flex',gap:'20px',flexWrap:'wrap'}}>
              {coreValues.map((value, index) => (
                  <div key={index} style={styles.valueItem}>
                      <h3 style={styles.valueTitle}>{value.title}</h3>
                      <p style={styles.text}>{value.description}</p>
                  </div>
              ))}
          </div>
        </div> */}

        {/* <MissionVision /> */}

        <h2 style={styles.sectionTitle}>Core Purpose</h2>
        <p style={styles.text}>
          Our core of being in this world is “To improve the life of people and serve 
          communities by providing knowledge and alternative solutions with an endeavour 
          to create more equitable, just and sustainable society.
        </p>

        <h2 style={styles.sectionTitle}>Our Vision</h2>
        <p style={styles.text}>
          To be a leading global company in Securing Sustainable Future for humanity. Our 
          vision is to be a global company in securing a sustainable future by accelerating 
          the implementation of sustainable development goals in an integrated manner through 
          shared value and action-oriented programmes.
        </p>

        <h2 style={styles.sectionTitle}>Our Mission</h2>
        <p style={styles.text}>
          Our mission is to contribute in achievements of goals of action oriented programmes 
          focusing on implementation of sustainable development agenda. Our mission will remain 
          global in our action-oriented goals and in partnerships to enhance the effectiveness of 
          programme through exchange of knowledge, solutions and resources.
        </p>
      </div>
    </div>
  );
};

const coreValues = [
  {
    title: "Accountability",
    description:"We focus on contribution, and we encourage the spirit of individuals who take responsibility for results. We hold ourselves accountable to our stakeholders, and for the performance and true value delivered as manifestation of our commitment.",
  },
  {
    title: "Excellence",
    description:"We strive for excellence to set and achieve highest professional, and technical standards as continual professional development. Goals. We encourage innovation, entrepreneurship and sharing learning in our approach of providing expertise.",
  },
  {
    title: "Integrity",
    description:
      "We believe integrity is core of institutions, integrity in leadership and in implementation of business process creates measurable positive impacts with value delivered.",
  },
  {
    title: "Social Transformation",
    description:
      "We follow wisdom of application the human capabilities to provide Knowledge, solution, technology and a sustainable society which is prosperous and secure. We intend to do this through visible change.",
  },
  {
    title: "Partnership & Collaboration",
    description:
      "We consider the growth of business and initiative depends on partnership and collaboration not on ownership. With collaborative partnership we intend to achieve economic and social goals of our professional intervention. We have global outlook, and we believe in leveraging resources from businesses and individuals to meet sustainable development goals.",
  },
];

const styles = {
  container: {
    maxWidth: "100%",
    margin: "auto",
    padding: "0 80px",
    fontFamily: "Arial, sans-serif",
  },
  title: {
    fontSize: "32px",
    fontWeight: "bold",
    textAlign: "left",
    color: "#0F2A4D",
    marginBottom: "20px",
  },
  sectionTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginTop: "30px",
    color: "#0F2A4D",
  },
  text: {
    fontSize: "16px",
    color: "#333",
    lineHeight: "1.6",
    marginBottom: "15px",
  },
  valueItem: {
    marginTop: "20px",
    width:'500px',
    padding: "10px",
    borderLeft: "5px solid #E3A000",
    backgroundColor: "#f9f9f9",
  },
  valueTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#0F2A4D",
  },
};

export default AboutUs;

import NewsHighlightCard from "../sharedComponents/newsHighlightCard";

const NewsHighlight = ({data}) => {
    return(
        <div style={{display:'flex',gap:'20px',justifyContent:'center',flexWrap:'wrap'}}>
            {data?.map((news, index) => (
                <NewsHighlightCard
                    key={index}
                    _id={news._id}
                    title={news.title}
                    category={news.by}
                    time={news.date}
                    image={news.image}
                />
            ))}
        </div>
    )
}

export default NewsHighlight;

// HomeGetInTouch
import React from "react";
import "../../styles/HomeGetInTouch.css";

const HomeGetInTouch = () => {
  return (
    <section className="contact-section">
      <div className="contact-container">
        <h2 className="contact-title">GET IN TOUCH</h2>
        <p className="contact-text">
          Ready to turn your project vision into reality? Contact us today to discover how our tailored consulting services can drive your success and shape a sustainable future.
        </p>
        <button className="contact-btn">Contact Us</button>
      </div>
    </section>
  );
};

export default HomeGetInTouch;

import React from "react";
import "../../styles/HomeWhyChooseUs.css";
import { FaLightbulb, FaHandshake, FaAward } from "react-icons/fa"; // Icons for visual appeal

const HomeWhyChooseUs = () => {
  return (
    // <></>
    <section className="why-choose">
      <h2 className="why-title">WHY CHOOSE GREENWORKS CONSULTING?</h2>
      <div className="why-container">
        <div className="why-card">
          <FaLightbulb className="why-icon" />
          <h3>Expertise & Innovation</h3>
          <p>
            Our global experience and deep understanding of the grant landscape enable us to provide cutting-edge solutions.
          </p>
        </div>

        <div className="why-card">
          <FaHandshake className="why-icon" />
          <h3>End-to-End Support</h3>
          <p>
            From proposal writing to project completion, we are with you every step of the way.
          </p>
        </div>

        <div className="why-card">
          <FaAward className="why-icon" />
          <h3>Commitment to Excellence</h3>
          <p>
            We adhere to the highest professional standards, ensuring transparency, accountability, and quality in every project.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HomeWhyChooseUs;

import React, { useState, useRef, useEffect } from "react";
import "../../styles/StoryComponent.css";

const sections = [
  { id: "Accountability", title: "Accountability", content: "We focus on contribution, and we encourage the spirit of individuals who take responsibility for results. We hold ourselves accountable to our stakeholders, and for the performance and true value delivered as manifestation of our commitment." },
  { id: "Excellence", title: "Excellence", content: "We strive for excellence to set and achieve highest professional, and technical standards as continual professional development. Goals. We encourage innovation, entrepreneurship and sharing learning in our approach of providing expertise." },
  { id: "Integrity", title: "Integrity", content: "We believe integrity is core of institutions, integrity in leadership and in implementation of business process creates measurable positive impacts with value delivered." },
  { id: "Social Transformation", title: "Social Transformation", content: "We follow wisdom of application the human capabilities to provide Knowledge, solution, technology and a sustainable society which is prosperous and secure. We intend to do this through visible change." },
  { id: "Partnership & Collaboration", title: "Partnership & Collaboration", content: "We consider the growth of business and initiative depends on partnership and collaboration not on ownership. With collaborative partnership we intend to achieve economic and social goals of our professional intervention. We have global outlook, and we believe in leveraging resources from businesses and individuals to meet sustainable development goals." }
];

const StoryComponent = () => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const sectionRefs = useRef({});

  useEffect(() => {
    if (sectionRefs.current[activeSection]) {
      sectionRefs.current[activeSection].scrollIntoView({ behavior: "smooth", block:'nearest' });
    }
  }, [activeSection]);

  return (
    <div className="story-container">
      {/* Sidebar */}
      <div className="sidebar">
        <h2 className="sidebar-title">Our Core Values</h2>
        <p className="sidebar-subtitle">Our core values are the guiding principles that help us make decisions and take actions every day</p>
        <div className="menu">
          {sections.map((section) => (
            <div
              key={section.id}
              className={`menu-item ${activeSection === section.id ? "active" : ""}`}
              onClick={() => setActiveSection(section.id)}
            >
              {section.title}
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="content">
        {sections.map((section) => (
          <section
            key={section.id}
            id={section.id}
            ref={(el) => (sectionRefs.current[section.id] = el)}
            className={activeSection === section.id ? "active-section" : ""}
          >
            <h2 style={{margin:0}}>{section.title}</h2>
            <p style={{marginBottom:0}}>{section.content}</p>
          </section>
        ))}
      </div>
    </div>
  );
};

export default StoryComponent;

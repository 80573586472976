import React from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Navbar.css";
import { Link } from "react-router-dom";

import { MdOutlineEmail } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import gsc_logo from '../assets/gwc_logo.png';

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav className="navbar">
      <div className="logo" onClick={()=>navigate('/')}>
        <img src={gsc_logo} alt="GFA Consulting Group"  />
        {/* <p style={{margin:0}}>
            GREEN WORK CONSULTING
        </p> */}
      </div>
      <ul className="nav-links">
        <li><Link to='/about' className="nav-link">About</Link></li>
        <li><Link className="nav-link">Projects</Link></li>
        <li><Link className="nav-link">Work With Us</Link></li>
        <li><Link to='/news' className="nav-link">News</Link></li>
      </ul>
      <div className="nav-icons">
        <i className="fas fa-envelope" onClick={
          ()=>window.open('mailto:Jsoftbd87gmail.com')
        }><MdOutlineEmail /></i>
        {/* <i className="fas fa-search"></i> */}
        <i className="fab fa-linkedin" onClick={
          ()=>window.open('https://www.linkedin.com/company/93344403/admin/dashboard/')
        }>
          <FaLinkedinIn />
        </i>
        <i className="fab fa-facebook" onClick={
          ()=>window.open('https://www.facebook.com/profile.php?id=61555218584816')
        }>
          <FaFacebookF />
        </i>
        <i className="fab fa-instagram">
          <FaInstagram />
        </i>
      </div>
    </nav>
  );
};

export default Navbar;

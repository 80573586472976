import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/NewsHighlightCard.css";

const NewsHighlightCard = ({ _id,image, category, time, title }) => {
  const navigate = useNavigate();
  return (
    <div className="highlight-news-card" onClick={()=>navigate(`/news-details/${_id}`)}>
      <img src={image} alt="news" className="highlight-news-image" />
      <div className="highlight-news-content">
        <p className="highlight-news-meta">
          <span className="highlight-news-category">{category}</span> | {time}
        </p>
        <h3 className="highlight-news-title">{title?.slice(0,67)}...</h3>
      </div>
    </div>
  );
};

export default NewsHighlightCard;

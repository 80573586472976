import React from "react";

import NewsHeroSection from "../components/News/NewsHeroSection";
import NewsHighlight from "../components/News/newsHighlisght";
// import LatestNews from "../components/News/LatestNews";
// import TrendingNews from "./components/TrendingNews";
// import LatestArticles from "./components/LatestArticles";
// import VideoNews from "./components/VideoNews";
// import PodcastSection from "./components/PodcastSection";
// import BasketballNews from "./components/BasketballNews";
import newsData from "../NewsData/newsData";

const NewsPage = () => {
  return (
    <div>
      <NewsHeroSection data={newsData} />
      <div style={{marginTop:'20px',padding:'30px'}}>
        <h2>Recent News</h2>
        <NewsHighlight data={newsData?.slice(1,3)} />
      </div>
      {/* <div style={{marginTop:'50px'}}>
        <LatestNews />
      </div> */}
      {/* <TrendingNews />
      <LatestArticles />
      <VideoNews />
      <PodcastSection />
      <BasketballNews /> */}
    </div>
  );
};

export default NewsPage;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from './Pages/Home';
import PageLayOut from "./Pages/PageLayOut";
import News from "./Pages/NewsPage";
import LatestNewsPage from "./Pages/LatestNewsPage";
import NewsDetails from "./Pages/NewsDetails";
import About from "./Pages/About";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<PageLayOut />} >
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/latest-news" element={<LatestNewsPage />} />
          <Route path="/news" element={<News />} />
          <Route path="/news-details/:id" element={<NewsDetails />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
